import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import SnipcartProductOptions from "../components/reusable/SnipcartProductOptions";
import Seo from "../components/global/Seo";
import ShopifyProductOptions from "../components/reusable/ShopifyProductOptions";

const ContainerOuter = styled.div`
  background: ${(props) => props.bg};
`;
const ContainerInner = styled.div`
  background: radial-gradient(
    farthest-corner at 30% 65%,
    ${(props) => props.bg},
    ${(props) => props.bg2}
  );
`;

export default function ProductSingle({ data }) {
  const product = data.wpProduct;
  const {
    abv,
    beerType,
    colour,
    colourDark,
    longDescription,
    shortDescription,
  } = product.acfDrinkInfo;

  const [isAvailable, setIsAvailable] = useState("test");
  const [hide, setHide] = useState();

  useEffect(() => {
    if (isAvailable === true) {
      setHide(false);
    } else {
      setHide(true);
    }
    console.log(isAvailable);
  }, [isAvailable]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelector("body").classList.add("loaded");
    }

    return () => {
      if (typeof window !== "undefined") {
        document.querySelector("body").classList.remove("loaded");
      }
    };
  }, []);

  return (
    <ContainerOuter
      className={
        product.acfBackgroundArt.backgroundArt
          ? "with-background-art single-beer__container"
          : "single-beer__container"
      }
      bg={colourDark}
    >
      <Seo seo={product.seo} />
      <ContainerInner
        className="single-beer__container--inner"
        bg={colour}
        bg2={colourDark}
      >
        <div className="single-beer__wrapper wrapper--small">
          <div className="single-beer__featured-image">
            <div className="image-shadow-wrap">
              <GatsbyImage
                className="productImage"
                onLoadStart={() => console.log("loading image")}
                backgroundColor="transparent"
                object-fit={"contain"}
                image={
                  product.acfCustomThumbnail.customThumbnail.localFile
                    .childImageSharp.gatsbyImageData
                }
              />
              <span className="shadow"></span>
            </div>
          </div>
          <div
            className={`single-beer__wrapper--content ${
              isAvailable !== "test" ? "" : "loaded"
            }`}
          >
            <h1 className="single-beer__heading heading heading--x-large">
              {product.title}
            </h1>
            <div className="single-beer__short-desc-text text text--small">
              {shortDescription}
            </div>
            <div className="single-beer__long-desc-text text text--small">
              {parse(longDescription)}
            </div>

            <div className="single-beer__beer-info">
              <div className="single-beer__beer-info__item single-beer__beer-info--abv">
                <label htmlFor="abv" className="heading heading--x-small">
                  ABV
                </label>
                <span className="abv">{abv}</span>
              </div>
            </div>

            <ShopifyProductOptions
              product={product}
              setIsAvailable={setIsAvailable}
              isAvailable={isAvailable}
            />
            {/* {product.acfDrinkInfo.productPurchaseOptions !== null ? (
            ) : (
              ""
            )} */}
          </div>
        </div>
        {product.acfBackgroundArt.backgroundArt ? (
          <GatsbyImage
            className="backgroundArt"
            objectFit="cover"
            image={
              product.acfBackgroundArt.backgroundArt.localFile.childImageSharp
                .gatsbyImageData
            }
          />
        ) : (
          ""
        )}
      </ContainerInner>
    </ContainerOuter>
  );
}

export const ProductSingleQuery = graphql`
  query ProductSingleQuery($id: String) {
    wpProduct(id: { eq: $id }) {
      title
      id
      databaseId
      seo {
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
      acfBeerDrinkInfo {
        inStock
        longDescription
        biabPurchaseOptions {
          optionPrice
          quantityInStock
          chooseProduct {
            ... on WpProduct {
              title
            }
          }
        }
      }
      acf_shopify {
        shopifyProductId
      }
      acfDrinkInfo {
        abv
        beerType
        colour
        colourDark
        longDescription
        inStock
        shortDescription
        productPurchaseOptions {
          optionName
          optionNameCustom
          optionPrice
          optionQuantity
        }
      }
      isGiftCard {
        isThisProductAGiftCard
      }
      acfBackgroundArt {
        backgroundArt {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acfCustomThumbnail {
        customThumbnail {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
